<template>
  <v-app style="background:white">
    <v-contaner style="background: #f0f2f5" class="mt-10">
      <v-row>
        <v-col md="6" offset-md="3">
          <h1 style="padding-top:50px" class="secondary--text mb-4 text-center">Амжилттай оролцсон танд баяр xүргэе.</h1>
          <v-card>
            <v-card-title v-if="currentSelectedUser" class="headline">
              {{ currentSelectedUser.name }}
            </v-card-title>
            <v-card-text>
              <z-result
                :asuulga-id="asuulgaId"
                :user-id="userId"
                :result-id="resultId"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="white--text px-10 mb-10"
          style="min-width: 0"
          color="secondary"
          @click="_openCrtWindow"
        >
          Гэрчилгээ аваx
        </v-btn>
      </v-row>
    </v-contaner>
  </v-app>
</template>
<style>
.v-ripple__container {
  display: none !important;
}
</style>
<script>
import ZResult from "./ZResult";
const fb = require("../../firebaseConfig.js");
export default {
  name: "ZResultPage",
  components: {
    ZResult,
  },
  // asuulga/VFMYBMmCkNJ9dBZsuN8Q/users/GaAo2AT6zujp2oPjJZEb/answered/yhUOZxd7fSwPMUODtndU
  props: {
    asuulgaId: {
      type: String,
      required: false,
    },
    userId: {
      type: String,
      required: false,
    },
    resultId: {
      type: String,
      required: false,
    },
  },
  data: () => ({}),

  watch: {},
  created() {},
  methods: {
    _openCrtWindow() {
      const routeData = this.$router.resolve({ name: "ViewCertification" });
      window.open(routeData.href, "_blank");
      this.dialog = false;
    },
  },
};
</script>
